<template>
        <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
body {
        background: #f8f9fa;
}

</style>
