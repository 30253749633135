<template>
    <HeaderComponent></HeaderComponent>
    <div class="w-100 top-0 z-index-0" id="hero" style="margin-top: -108px; background-image: url('/bg.jpg');"></div>
    <div class="container text-center" id="welcome" style="margin-top: 20px">
        Mr and Mrs Duncan Wilson<br/>invite you to celebrate with them<br/>the marriage of their daughter<br/>Sarah-Jane<br/>to<br/>Louis Bougeard<br/>at St Chad’s College Chapel, Durham<br/>DH1 3RH
        <br/>on Friday the fifth of April 2024<br/>at two o'clock<p/>Reception at St Chad's College to follow<br/>Carriages at half past eleven
    </div>
    <FooterComponent></FooterComponent>
</template>

<script>

import {defineComponent} from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
    components: {FooterComponent, HeaderComponent}
})
</script>

<style scoped>
#hero {
    background-size: cover;
    background-position: center 0;
    height: 50vh;
}

#welcome {
    font-family: "kristal", sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    font-size: 16pt;
}
.openCap {
    font-family: "kristal-opencaps", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.ornaments {
    font-family: "kristal-ornaments", sans-serif;
    font-weight: 400;
    font-style: normal;
}
@media (max-width: 450px) {
    #welcome {
        font-size: 10pt;
        font-weight: 300;
    }
    #hero {
        background-size: cover;
        background-position: center 10px;
        height: 50vh;
    }
}
@media (min-width: 1200px) {
    #hero {
        background-position: center -80px;
    }
}

@media (min-width: 1600px) {
    #hero {
        background-position: center -200px;
    }
}
</style>