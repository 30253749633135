<template>
    <HeaderComponent></HeaderComponent>
    <div class="container mt-4">
        <div class="card">


            <div class="card-header pt-4 pb-0">
                <div class="card-title">
                    <h4>Travel</h4>
                </div>
            </div>
            <div class="card-body pt-1 pb-1">
                St Chad’s College is a 20 minute scenic (but hilly!) walk from Durham train station through the city.
                If you are planning to fly, the nearest airport is Newcastle and you can get the Metro then train to
                Durham
                train station quite easily.
                Please note that if you are driving, there is no parking at St Chad’s College so please use one of the
                multi-storey car parks in Durham city centre instead. If you’d like to drop someone off outside of the
                college, you will incur a <a href="https://www.durham.gov.uk/article/3437/Durham-Road-User-Charge-Zone" target="_blank" class="link-primary text-decoration-underline">&pound;2 charge</a> for driving up the bailey.
            </div>


            <div class="card-header pt-2 pb-0">
                <div class="card-title">
                    <h4>Accommodation</h4>
                </div>
            </div>
            <div class="card-body pt-1 pb-0">
                Sarah and Louis will be staying at St Chad’s College. The college offers reasonably priced bed and
                breakfast accommodation, but please note that this is our old uni halls and rooms are comfortable but
                quite simple
                -
                you may not have an en suite bathroom. <a class="link-primary text-decoration-underline"
                                                          href="https://www.stchads.ac.uk/commercial/bed-breakfast/"
                                                          target="_blank">Click here for more information</a>
                and call to book; let them know you are one of our
                guests and you will receive a 10% discount for the night of the wedding.
                <p>
                    Many of our friends and family members will be staying at the <a
                        class="link-primary text-decoration-underline"
                        href="https://www.marriott.com/en-gb/hotels/ncldd-delta-hotels-durham-royal-county/overview/"
                        target="_blank">Marriott Durham Royal County</a> which is a
                    short
                    walk from the college, but there are
                    lots
                    of lovely hotels in Durham city centre, all of which are walkable from St Chad's.</p>
            </div>


            <div class="card-header pt-0 pb-0">
                <div class="card-title">
                    <h4>Gifts</h4>
                </div>
            </div>
            <div class="card-body pt-1">
                We are absolutely delighted to have you join us to celebrate our wedding and so an additional gift is
                not
                necessary. However, if you are considering a gift, we have set up a <a href="https://prezola.com/wishlists/10288845/" target="_blank" class="link-primary text-decoration-underline">Prezola page</a>. Your love and support are the most important things to us.
            </div>
            <div class="card-header pt-0 pb-0">
                <div class="card-title">
                    <h4>Dress Code</h4>
                </div>
            </div>
            <div class="card-body pt-1">
                Lounge suits, although morning suits welcomed.
            </div>

            <div class="card-header pt-0 pb-0">
                <div class="card-title">
                    <h4>The next day</h4>
                </div>
            </div>
            <div class="card-body pt-1">
                If you are spending the day after the wedding in Durham, we plan to go for a few drinks at The Swan and Three Cygnets on Elvet Bridge in Durham city centre at around midday. Please feel free to join us.
            </div>
        </div>
    </div>

    <FooterComponent></FooterComponent>
</template>

<script>

import {defineComponent} from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
    components: {FooterComponent, HeaderComponent}
})
</script>

<style scoped>
h4 {
    font-family: "kristal", sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
}
</style>