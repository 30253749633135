<template>
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light top-0 border-bottom z-index-3 shadow w-100 mt-sm-2 mt-md-4 my-3 py-2 bg-light" :class="{'rounded-pill': hideMenu}">
            <div class="container-fluid">
                <!-- <a class="navbar-brand" href="/">Sarah & Louis</a> -->
                <a class="navbar-brand" href="/">Sarah & Louis</a>

                <button @click="hideMenu = !hideMenu" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span v-if="hideMenu" class="navbar-toggler-icon"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 6l16 0" /><path d="M4 12l16 0" /><path d="M4 18l16 0" /></svg></span>
                    <span v-if="!hideMenu" class="navbar-toggler-icon"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg></span>
                </button>
                <div :class="{'collapse': hideMenu}" class=" navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav mx-auto">

                    </ul>
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" href="/information">Information</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" target="_blank" href="https://prezola.com/wishlists/10288845/">Gifts</a>
                        </li>
                        <a href="/rsvp" class="mx-3 btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1" style="padding-top: 11px">RSVP</a>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>

export default {
    data() {
        return {
            active: "home",
            hideMenu: true,
        }
    },
}
</script>

<style scoped>
.navbar-brand {
    font-family: "regina", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40pt;
    margin: -15pt 0 -25pt -20pt;
}
@media (max-width: 450px) {
    .navbar-brand {
        font-size: 20pt;
    }
}
</style>