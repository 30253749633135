<template>
    <div v-for="p in pages" :key="p" class="a4-sheet">
        <div v-for="r in p" :key="r" class="rsvp">
            <div class="names">
                <span v-for="p in r.people" :key="p.guestId">{{ p.FirstName }}<br/></span>
            </div>
            <div class="text-center mt-4">
                <h4>RSVP</h4>
                Please RSVP by 5<sup>th</sup> March via this website:<br/>
                <img :src="'https://qrcode-service.larkhall-butchers.workers.dev/https://wedding.bougeard.co.uk/rsvp/'+r.rsvpCode"
                     style="width: 30mm">
                <br/>
                <span style="font-family: sans-serif; font-size: 10pt">
                    https://wedding.bougeard.co.uk<br/></span>
                    <span style="font-family: sans-serif">
                RSVP Code:<br/>{{ r.rsvpCode }}</span>
                <!--<h4 class="mt-4">Dress Code</h4>
                Lounge suits,<br/>morning suits welcomed. -->
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            pages: [],
            loginToken: ""
        }
    },
    async mounted() {
        const resp = await axios.get("https://api.bouge.workers.dev/admin/groups", {
            headers: {
                'Authorization': `Bearer ${this.loginToken}`
            }
        })
        const res = await axios.get("https://api.bouge.workers.dev/admin/status", {
            headers: {
                'Authorization': `Bearer ${this.loginToken}`
            }
        })
        let groups = []
        for (let g of resp.data) {
            g.people = res.data.filter(c =>
                c.rsvpId === g.rsvpId
            )
            groups.push(g)
        }
        console.log(groups)
        this.pages = this.chunkArray(groups, 4)

    },
    methods: {
        chunkArray(array, chunkSize) {
            const result = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                const chunk = array.slice(i, i + chunkSize);
                result.push(chunk);
            }
            return result;
        }
    }
}
</script>

<style scoped>
* {
    background: white;
    font-family: "kristal", sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    color: black;
}

.a4-sheet {
    width: 210mm;
    height: 297mm;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    border: solid 0pt crimson;

}
.names {
    height: 30mm;
    padding-top: 10mm;
    padding-left: 10mm;
    font-size: 8pt;
    width: fit-content;
}

.rsvp {
    display: inline-block;
    width: 104.24mm;
    height: 148mm;
    border: solid 0pt #333;
    padding: 10px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 1mm;

}

.rsvp:nth-child(2n) {
    margin-right: 0mm;
}
</style>