import { createRouter, createWebHistory } from 'vue-router';

import HomePage from "@/pages/HomePage.vue"
import RSVPpage from "@/pages/RSVPpage.vue"
import InformationPage from "@/pages/InformationPage.vue"
import adminPage from "@/pages/adminPage.vue"
import printPage from "@/pages/printPage.vue";

const routes = [
    { path: '/', component: HomePage},
    { path: '/rsvp', component: RSVPpage},
    { path: '/rsvp/:rsvpCode', component: RSVPpage, props: true},
    { path: '/information', component: InformationPage},
    { path: '/system/wedding/admin', component: adminPage},
    { path: '/system/wedding/rsvp', component: printPage},
    /*{
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFoundPage,
    },*/
]

const router = createRouter({
    history: createWebHistory(),
    routes
});
export default router;