<template>
    <div class="container">
        <div v-if="!loggedIn" class="row">
            <div class="col-12 col-md-4"></div>
            <div class="col-12 col-md-4"><div class="card mt-10 w-100">
                <div class="card-header">
                    <div class="card-title">
                        <h4>Login</h4>
                    </div>
                </div>
                <div class="card-body">
                    <form @submit.prevent="getRsvp">
                        <input class="form-control" v-model="loginToken" type="password" placeholder="Password"/>
                    </form>
                </div>
                <div class="card-footer">
                    <div @click="getRsvp" class="btn btn-primary">Login</div>
                </div>
            </div></div>
            <div class="col-12 col-md-4"></div>
        </div>

        <div v-if="loggedIn" class="card w-100">
            <div class="card-header d-flex justify-content-between">
                <div class="card-title">
                    <h4>RSVPs</h4>
                </div>
                <div @click="getRsvp" class="btn btn-outline-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-reload" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" /><path d="M20 4v5h-5" /></svg>
                </div>
            </div>
            <div class="card-body">
                <table class="table w-100 table-bordered table-striped">
                    <thead>
                    <th>Name</th>
                    <th>Group</th>
                    <th>Status</th>
                    <th>RSVP</th>
                    <th>Menu</th>
                    <th>Allergens</th>
                    </thead>
                    <tbody>
                    <tr :class="{'bg-gradient-success': guest.rsvpStatus === 'submitted','text-white': guest.rsvpStatus === 'submitted' }" v-bind:key="guest.guestId" v-for="guest in rsvps">
                        <td>
                            {{ guest.FirstName }} {{ guest.LastName }}
                        </td>
                        <td>
                            {{ guest.rsvpId }} ({{guest.rsvpCode}})
                        </td>
                        <td>
                            {{ guest.rsvpStatus }}
                        </td>
                        <td>
                            {{ guest.RSVP }}
                        </td>
                        <td>
                            {{ guest.menu }}
                        </td>
                        <td class="max-width-200 overflow-x-scroll custom-scroll">
                            {{ guest.diet }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            rsvps: [],
            loggedIn: false,
            loginToken: "",
        }
    },
    methods: {
        async getRsvp() {
            try {
                const resp = await axios.get("https://api.bouge.workers.dev/admin/status", {
                    headers: {
                        'Authorization': `Bearer ${this.loginToken}`
                    }
                })
                this.rsvps = resp.data;
                this.loggedIn = true;
            } catch (e) {
                alert("invalid login")
            }
        }
    }
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 10px;
}
.custom-scroll {
    ::-webkit-scrollbar {
        width: 10px;
    }
}
</style>