<template>
    <HeaderComponent></HeaderComponent>
    <div class="container">
        <div v-if="!hasCode" class="row">
            <div class="col-sm"></div>
            <div class="col-sm col-sm-12 col-md-6">
                <div class="card w-100 shadow-md mt-4 mt-md-6 mb-4">
                    <div class="card-header">
                        <div class="card-title text-center">
                            <span class="h1">RSVP</span></div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm"></div>
                            <div class="col-sm-12 col-md-6">
                                <div class="alert alert-danger" v-if="hasError">Invalid Invite Code</div>
                                <div class="form-group">
                                    <label for="rsvpCodeInput" class="form-control-label">RSVP Code</label>
                                    <form @submit.prevent="validateCode">
                                        <input class="form-control text-center" id="rsvpCodeInput" placeholder="XXX-XXX"
                                               v-model="rsvpCode"
                                               @input="applyRsvpMask"/>
                                    </form>
                                </div>
                            </div>
                            <div class="col-sm"></div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                        <button @click="validateCode" type="button" class="btn bg-gradient-primary">OK</button>
                    </div>
                </div>
            </div>
            <div class="col-sm"></div>
        </div>

        <div v-if="hasCode" class="row">
            <div class="col-sm"></div>
            <div class="col-sm col-sm-12 col-md-8 mt-2 mb-6 mt-md-4">
                <div class="card w-100 shadow-sm">
                    <div class="card-header">
                        <div class="card-title">
                            <span class="h1">RSVP</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="list-group">
                            <li v-bind:key="guest.id" v-for="guest in guests"
                                class="list-group-item">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ guest.FirstName }} {{ guest.LastName }}</span>
                                    <!--<div class="form-check form-switch">
                                        <label class="form-check-label" for="flexSwitchCheckDefault">Attending</label>
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                               v-model="guest.attending">
                                    </div> -->
                                    <div class="form-check">
                                        <input :disabled="rsvpStatus === 'submitted'" class="form-check-input"
                                               type="checkbox" value="" :id="'attendingCheck'+guest.id"
                                               v-model="guest.attending">
                                        <label class="custom-control-label"
                                               :for="'attendingCheck'+guest.id">Attending</label>
                                    </div>

                                </div>
                                <div class="w-100 mt-2 border-top" v-if="guest.attending">
                                    <h6 class="mt-2">Food options: </h6>
                                    <span class="bold">Starter:</span>
                                    <div class="form-check">
                                        <input :disabled="rsvpStatus === 'submitted'" value="meat"
                                               v-model="guest.food.starter" class="form-check-input" type="radio"
                                               :name="'starter'+guest.id" :id="'starterMeat'+guest.id">
                                        <label class="custom-control-label" :for="'starterMeat'+guest.id">Sliced duck
                                            breast with an Asian slaw, sweet ginger, chilli and lime dressing</label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input :disabled="rsvpStatus === 'submitted'" value="vegan"
                                               v-model="guest.food.starter" class="form-check-input" type="radio"
                                               :name="'starter'+guest.id" :id="'starterVegan'+guest.id">
                                        <label class="custom-control-label" :for="'starterVegan'+guest.id">Vine tomato
                                            bruschetta with homemade pesto and balsamic jus (vegan)</label>
                                    </div>
                                    <span class="bold">Main:</span>
                                    <div class="form-check">
                                        <input :disabled="rsvpStatus === 'submitted'" value="meat"
                                               v-model="guest.food.main" class="form-check-input" type="radio"
                                               :name="'main'+guest.id" :id="'mainMeat'+guest.id">
                                        <label class="custom-control-label" :for="'mainMeat'+guest.id">Roast chicken
                                            breast with crisp serrano ham and mushroom au lait sauce</label>
                                    </div>
                                    <div class="form-check mb-6">
                                        <input :disabled="rsvpStatus === 'submitted'" value="vegan"
                                               v-model="guest.food.main" class="form-check-input" type="radio"
                                               :name="'main'+guest.id" :id="'mainVegan'+guest.id">
                                        <label class="custom-control-label" :for="'mainVegan'+guest.id">Cauliflower
                                            steak Kiev (vegan)</label>
                                    </div>

                                    <div class="form-check">
                                        <input :disabled="rsvpStatus === 'submitted'" class="form-check-input"
                                               type="checkbox" @change="changeAllergen(guest)"
                                               :id="'allergens'+guest.id" v-model="guest.food.allergens">
                                        <label class="custom-control-label" :for="'allergens'+guest.id">Allergens or
                                            dietary requirements</label>
                                    </div>
                                    <div v-if="guest.food.allergens" class="form-group">
                                        <label :for="'allergenDetails'+guest.id">Allergen or dietary
                                            requirements:</label>
                                        <textarea :disabled="rsvpStatus === 'submitted'"
                                                  v-model="guest.food.allergenDetails" class="form-control"
                                                  :id="'allergenDetails'+guest.id" rows="3"></textarea>
                                    </div>
                                </div>

                            </li>
                        </div>
                    </div>
                    <dv class="card-footer" v-if="isSaved">
                        <div class="alert alert-success">Saved - Please submit when complete</div>
                    </dv>
                    <div v-if="rsvpStatus !== 'submitted'" class="card-footer w-100 d-flex justify-content-between">
                        <div @click="save" class="btn btn-secondary">Save</div>
                        <div @click="submit" class="btn bg-gradient-primary">Save & Submit</div>
                    </div>
                    <div v-if="rsvpStatus == 'submitted'" class="card-footer w-100 d-flex justify-content-between">
                        Thank you for RSVPing
                    </div>
                </div>
            </div>
            <div class="col-sm"></div>
        </div>

    </div>
    <FooterComponent></FooterComponent>
</template>

<script>


import axios from "axios";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
    components: {FooterComponent, HeaderComponent},
    data() {
        return {
            hasCode: false,
            hasError: false,
            rsvpCode: "",
            guests: [],
            rsvpStatus: null,
            isSaved: false,
        }
    },
    mounted() {
        if (this.$route.params.rsvpCode) {
            this.rsvpCode = this.$route.params.rsvpCode;
            this.validateCode();
        }
    },
    methods: {
        changeAllergen(guest) {
            if (!guest.food.allergens) {
                guest.food.allergenDetails = ""
            }
        },
        applyRsvpMask() {
            let value = this.rsvpCode.toUpperCase();
            value = value.replace(/[^A-Z0-9-]/gi, ''); // Allow only letters, numbers, and hyphen
            value = value.replace(/^([A-Z0-9]{3})([A-Z0-9])/, '$1-$2'); // Insert hyphen after 3 chars
            this.rsvpCode = value.substring(0, 7); // Limit to 7 characters
        },
        async validateCode() {
            try {
                this.hasError = false;
                const r = await axios.get("https://api.bouge.workers.dev/rsvp/" + this.rsvpCode)
                console.log(r.data);
                this.rsvpStatus = r.data.rsvpStatus;
                this.guests = r.data.guests;
                for (const i in this.guests) {
                    //this.guests[i].attending = false;
                    if (this.guests[i].menu) {
                        const splitMenu = this.guests[i].menu.split(",")
                        const starter = splitMenu[0].split(": ")[1]
                        const allergens = this.guests[i].diet.split(" | ");
                        const main = splitMenu[1].split(": ")[1]
                        this.guests[i].food = {
                            starter,
                            main,
                            allergenDetails: allergens[1],
                            allergens: allergens[0].split(": ")[1] === "true"
                        }
                    } else {
                        this.guests[i].food = {starter: null, main: null, allergens: false, allergenDetails: ""}
                    }

                }
                const stateObj = {path: "/rsvp/" + this.rsvpCode};
                history.pushState(stateObj, "RSVP", "/rsvp/" + this.rsvpCode);
                this.hasCode = true;
            } catch (e) {
                this.hasError = true;
            }
        },
        async save() {
            await axios.post("https://api.bouge.workers.dev/rsvp/" + this.rsvpCode, this.guests)
            this.isSaved = true;
            setTimeout(function (){
                this.isSaved = false;
            }, 2000)
        },
        async submit() {
            await this.save();
            await axios.post("https://api.bouge.workers.dev/rsvp-submit/" + this.rsvpCode);
            this.rsvpStatus = "submitted"
            this.isSaved = true;
        }
    }
}
</script>

<style scoped>
.form-switch .form-check-input:checked {
    background-color: var(--bs-primary);
}
</style>